import React, { useState } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyInput from '../../../components/forms/input'
import MyGroupSelect from '../../../components/select/groupSelect'
import MyImageUpload from '../../../components/forms/imageUpload'
import MySwitch from '../../../components/forms/switch'

const AddAccount = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.nickname) {
      setErrorMsg({nicknameEmpty: '客服名称不能为空'})
      return
    }
    if (!formValue.username) {
      setErrorMsg({usernameEmpty: '客服登陆账号不能为空'})
      return
    }
    if (!formValue.password) {
      setErrorMsg({passwordEmpty: '客服登陆密码不能为空'})
      return
    }
    if (!formValue.confirmPassword) {
      setErrorMsg({confirmPasswordEmpty: '确认密码不能为空'})
      return
    }
    if (formValue.password.trim() !== formValue.confirmPassword.trim()) {
      setErrorMsg({passwordMismatch: '新密码和确认密码不一致'})
      return
    }
    if (!formValue.group_oid) {
      setErrorMsg({groupEmpty: '所属分组不能为空'})
      return
    }

    axios.post('/p/customer_service/account/add', {
      merchant_oid: merchantOid,
      group_oid: formValue.group_oid,
      avatar: formValue.avatar,
      nickname: formValue.nickname,
      username: formValue.username,
      password: formValue.password,
      is_hide: formValue.is_hide,
      sort: Number(formValue.sort)
    })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          message.success('创建成功！')
          props.getAccountList()
        } else if (response.errcode === 1) {
          setErrorMsg({usernameError: response.msg})
        } else if (response.errcode === 2) {
          setErrorMsg({passwordError: response.msg})
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    setFormValue({})
    props.isCancel()
  }

  return (
    <>
      <Modal
        destroyOnClose={true}
        title="创建客服"
        width='500px'
        open={props.isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="取消"
        okText="创建">

        <MyImageUpload 
          id="avatar"
          label='客服头像'
          value={formValue.avatar}
          setValue={setFormValue}
        />

        <MyInput
          id="nickname"
          required
          autoFocus
          maxLength={64}
          label='客服名称'
          placeholder='请输入客服的名称'
          value={formValue.nickname}
          onChange={setFormValue}
          errorMsg={errorMsg.nicknameEmpty}
        />

        <MyInput
          id="username"
          required
          maxLength={64}
          label='客服账号'
          placeholder='请输入客服的登陆账号'
          value={formValue.username}
          onChange={setFormValue}
          errorMsg={errorMsg.usernameEmpty || errorMsg.usernameError}
        />

        <MyInput
          id="password"
          required
          type="password"
          label="客服密码"
          placeholder='请输入客服登陆密码'
          value={formValue.password}
          onChange={setFormValue}
          errorMsg={errorMsg.passwordEmpty || errorMsg.passwordError}
        />

        <MyInput
          id="confirmPassword"
          required
          type="password"
          label="确认密码"
          placeholder='确认客服登陆密码'
          value={formValue.confirmPassword}
          onChange={setFormValue}
          errorMsg={errorMsg.confirmPasswordEmpty || errorMsg.passwordMismatch}
        />

        <MyGroupSelect
          id='group_oid'
          required
          label='选择分组'
          value={formValue.group_oid}
          onChange={setFormValue}
          errorMsg={errorMsg.groupEmpty}
        />

        <MySwitch 
          id='is_hide'
          label='是否隐藏'
          onChange={setFormValue}
        />

        <MyInput
          type='number'
          id="sort"
          label='排序'
          value={formValue.sort}
          onChange={setFormValue}
          styles={{ width: '60px' }}
          min={0}
          max={1000}
        />
      </Modal>
    </>
  )
}

export default AddAccount
