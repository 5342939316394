import React, { useEffect, useState } from 'react'
import axios from 'axios'
import EditWelcomeMsg from './editWelcomeMsg'
import MyQrcode from '../../../components/general/qrcode'

export default function CustomerServiceConfig() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [customerServiceConfig, setCustomerServiceConfig] = useState({})
  const [propsData, setPropsData] = useState({})
  const [isEditWelcomeMsgVisible, setIsEditWelcomeMsgVisible] = useState(false)
  const [isAccessAddressVisible, setIsAccessAddressVisible] = useState(false)

  const getCustomerServiceConfig = () => {
    axios.post('/p/customer_service/config/get', {
      merchant_oid: merchantOid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setCustomerServiceConfig(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getCustomerServiceConfig()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid])

  const handleWelcomeMsg = () => {
    setIsEditWelcomeMsgVisible(true)
    setPropsData({ info: customerServiceConfig })
  }

  const previewAccessAddress = () => {
    setIsAccessAddressVisible(true)
    setPropsData({ accessAddress: 'https://kf-c.yunzhaosoft.com/' + merchantOid})
  }

  const openWorkbench = () => {
    window.open('https://kf-b.yunzhaosoft.com/login/' + merchantOid, '_blank');
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">配置信息</div>
        </div>

        <div className='public-main-style'>
          <div className='public-main-style4'>
            <div className='public-main-style4-items'>
              <div className='public-main-style4-item'>
                <div className='public-main-style4-item-left'>
                  <div className='public-main-style4-item-title'>客服工作台</div>
                  <div className='public-main-style4-item-description'>客服老师接待咨询学生的聊天工作台</div>
                </div>
                <div className='public-main-style4-item-right'>
                  <div className='public-main-style4-item-buttonPrimary' onClick={openWorkbench}>进入</div>
                </div>
              </div>
            </div>

            <div className='public-main-style4-items'>
              <div className='public-main-style4-item'>
                <div className='public-main-style4-item-left'>
                  <div className='public-main-style4-item-title'>学生访问地址</div>
                  <div className='public-main-style4-item-description'>点击查看可复制访问链接和下载二维码，可在微信小程序或公众号跳转访问</div>
                </div>
                <div className='public-main-style4-item-right'>
                  <div className='public-main-style4-item-buttonPrimary' onClick={previewAccessAddress}>查看</div>
                </div>
              </div>
            </div>

            <div className='public-main-style4-items'>
              <div className='public-main-style4-item'>
                <div className='public-main-style4-item-left'>
                  <div className='public-main-style4-item-title'>统一欢迎语</div>
                  <div className='public-main-style4-item-description'>
                    {customerServiceConfig.welcome_msg}
                  </div>
                </div>
                <div className='public-main-style4-item-right'>
                  <div className='public-main-style4-item-buttonPrimary' onClick={handleWelcomeMsg}>编辑</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isEditWelcomeMsgVisible && <EditWelcomeMsg
        data={propsData}
        getCustomerServiceConfig={getCustomerServiceConfig}
        isOpen={isEditWelcomeMsgVisible}
        isCancel={() => { setIsEditWelcomeMsgVisible(false) }}
      />}

      {isAccessAddressVisible && <MyQrcode
        data={propsData}
        isOpen={isAccessAddressVisible}
        isCancel={() => { setIsAccessAddressVisible(false) }}
      />}
    </div>
  )
}
