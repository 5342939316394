import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyInput from '../../../components/forms/input'
import MyGroupSelect from '../../../components/select/groupSelect'
import MyImageUpload from '../../../components/forms/imageUpload'
import MySwitch from '../../../components/forms/switch'

const EditAccount = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  useEffect(() => {
    axios.post('/p/customer_service/account/detail/get', {
      merchant_oid: merchantOid,
      account_oid: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setFormValue(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid, props.data])

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.nickname) {
      setErrorMsg({nicknameEmpty: '客服名称不能为空'})
      return
    }
    if (!formValue.group_oid) {
      setErrorMsg({groupEmpty: '所属分组不能为空'})
      return
    }

    axios.post('/p/customer_service/account/edit', { 
      merchant_oid: merchantOid,
      account_oid: props.data.oid,
      group_oid: formValue.group_oid,
      avatar: formValue.avatar,
      nickname: formValue.nickname,
      is_hide: formValue.is_hide,
      sort: Number(formValue.sort)
    })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          props.getAccountList()
          message.success('修改成功！')
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    props.isCancel()
  }

  return (
    <Modal 
      title='编辑客服'
      destroyOnClose={true}
      width='500px'
      open={props.isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="取消"
      okText="保存">

      <MyImageUpload 
        id="avatar"
        label='客服头像'
        value={formValue.avatar}
        setValue={setFormValue}
      />

      <MyInput
        id="nickname"
        required
        autoFocus
        maxLength={64}
        label='客服名称'
        placeholder='请输入客服的名称'
        value={formValue.nickname}
        onChange={setFormValue}
        errorMsg={errorMsg.nicknameEmpty}
      />

      <MyGroupSelect
        id='group_oid'
        required
        label='选择分组'
        value={formValue.group_oid}
        onChange={setFormValue}
        errorMsg={errorMsg.groupEmpty}
      />

      <MySwitch 
        id='is_hide'
        label='是否隐藏'
        checked={formValue.is_hide}
        onChange={setFormValue}
      />

      <MyInput
        type='number'
        id="sort"
        label='排序'
        value={formValue.sort}
        onChange={setFormValue}
        styles={{ width: '60px' }}
        min={0}
        max={1000}
      />
    </Modal>
  )
}

export default EditAccount
