import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Tooltip } from 'antd'
import MyIcon from '../../../components/general/icon'
import AccountFilter from './filter'
import AddAccount from './add'
import EditAccount from './edit'
import DeleteAccount from './delete'
import ResetPassword from './resetPwd'
import MyAvatar from '../../../components/general/avatar'

export default function CustomerServiceAccount() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [accountList, setAccountList] = useState([])
  const [propsData, setPropsData] = useState({})
  const [isAddAccountVisible, setIsAddAccountVisible] = useState(false)
  const [isEditAccountVisible, setIsEditAccountVisible] = useState(false)
  const [isResetPasswordVisible, setIsResetPasswordVisible] = useState(false)
  const [isDeleteAccountVisible, setIsDeleteAccountVisible] = useState(false)

  const initialConditions = {
    group_oid: '',
    keywords: '',
    sort_filter: 0,
  }
  const [filterValue, setFilterValue] = useState(initialConditions)

  const getAccountList = () => {
    axios.post('/p/customer_service/account/list/get', { 
      merchant_oid: merchantOid,
      group_oid: filterValue.group_oid,
      keywords: filterValue.keywords,
      sort_filter: filterValue.sort_filter,
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setAccountList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getAccountList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, filterValue])

  const editAccount = (oid) => {
    setIsEditAccountVisible(true)
    setPropsData({ oid: oid })
  }

  const resetPassword = (oid, name) => {
    setIsResetPasswordVisible(true)
    setPropsData({ oid: oid, name: name})
  }

  const deleteAccount = (oid, name) => {
    setIsDeleteAccountVisible(true)
    setPropsData({ oid: oid, name: name})
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">客服账号</div>
          <AccountFilter filterValue={filterValue} setFilterValue={setFilterValue} isAddVisible={() => { setIsAddAccountVisible(true) }}  />
        </div>

        <div className='public-main-style'>
          <div className='public-main-style2'>
            <table className='public-main-style2-item'>
              <thead>
                <tr className='public-main-style2-thead'>
                  <th style={{textAlign: 'left', paddingLeft: '20px'}}>客服名称</th>
                  <th>账号</th>
                  <th>是否绑定微信</th>
                  <th>是否隐藏</th>
                  <th>所属分组</th>
                  <th>排序</th>
                  <th style={{width: '50px'}}></th>
                </tr>
              </thead>

              <tbody>
                {accountList && (accountList.length > 0 ? (accountList.map((item) => (
                  <tr className='public-main-style2-tbody' key={item.oid} onClick={() => { editAccount(item.oid) }}>
                    <td style={{textAlign: 'left', padding: '10px 0 10px 20px', display: 'flex', alignItems: 'center', borderRadius: '50%'}}>
                      <MyAvatar userObject={item} avatarSize={50} fontSize='18px'/>
                      <div style={{marginLeft: '10px'}}>{item.nickname}</div>
                    </td>
                    <td>{item.username}</td>
                    <td>{item.is_bind_wechat}</td>
                    <td>{item.is_hide}</td>
                    <td>{item.group}</td>
                    <td>{item.sort}</td>
                    <td style={{width: '100px'}}>
                      <div className='public-main-style2-operation-box'>
                        <Tooltip placement="bottom" color='#1A1B1C' title="重置密码">
                          <div className='public-main-style2-operation' onClick={(event) => {event.stopPropagation(); resetPassword(item.oid, item.nickname)}}>
                            <MyIcon name='chongzhimima' />
                          </div>
                        </Tooltip>
                        <Tooltip placement="bottom" color='#1A1B1C' title="删除">
                          <div className='public-main-style2-operation' onClick={(event) => {event.stopPropagation(); deleteAccount(item.oid, item.nickname)}}>
                            <MyIcon name='huishouzhan' />
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))) : (
                  <tr className='public-main-style2-tbodyEmpty'>
                    <td colSpan="6"><span>暂无数据</span></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isAddAccountVisible && <AddAccount
        getAccountList={getAccountList} 
        isOpen={isAddAccountVisible} 
        isCancel={() => { setIsAddAccountVisible(false) }} 
      />}

      {isEditAccountVisible && <EditAccount
        data={propsData}
        getAccountList={getAccountList} 
        isOpen={isEditAccountVisible} 
        isCancel={() => { setIsEditAccountVisible(false) }} 
      />}

      {isDeleteAccountVisible && <DeleteAccount
        data={propsData}
        getAccountList={getAccountList} 
        isOpen={isDeleteAccountVisible} 
        isCancel={() => { setIsDeleteAccountVisible(false) }} 
      />}

      {isResetPasswordVisible && <ResetPassword
        data={propsData}
        isOpen={isResetPasswordVisible} 
        isCancel={() => { setIsResetPasswordVisible(false) }} 
      />}
    </div>
  )
}
